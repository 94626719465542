import logo from './logo.svg';
import flightlogo from './flight.svg';
import './App.css';
import { Box, Button } from '@mui/material';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={flightlogo} className="App-logo" alt="logo" />
        <p>
          Easy Flight Rewards On The Way.
        </p>
        <form
        action="https://buttondown.email/api/emails/embed-subscribe/easy_flight_rewards"
        method="post"
        target="popupwindow"
        onsubmit="window.open('https://buttondown.email/easy_flight_rewards', 'popupwindow')"
        class="embeddable-buttondown-form"
      >
        <Box sx={{ display:"flex", flexDirection: 'column' }}>
          <label for="bd-email">Enter your email</label>
          <Box sx={{ display:"flex", flexDirection: 'column', m:3 }}>
            <input type="email" name="email" id="bd-email" placeholder="Your email here"/>
          </Box>
          <Button type="submit" variant="contained">Send Me Deals!</Button>
        </Box>
      </form>
      </header>
    </div>
  );
}

export default App;
